<template>
  <div class="coianter">
    <div class="idteail">
      <div class="idtname">公共建筑能效测评汇总表</div>
      <div class="back" @click="goback()">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="dtcontent">
      <div class="tableform">
        <el-form ref="form" :model="project" label-width="180px">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <div class="gezi">
                  <el-form-item label="项目名称：">
                    {{ project.name }}
                  </el-form-item>
                </div>
                <div class="gezi">
                  <el-form-item label="建筑面积（m²）/层数：">
                    {{ project.building_area }}/{{ project.floor }}
                  </el-form-item>
                </div>
                <div class="gezi">
                  <el-form-item label="建设单位：">
                    {{ project.build_company }}
                  </el-form-item>
                </div>
                <div class="gezi">
                  <el-form-item label="施工单位：">
                    <el-input v-model="project.work_build" placeholder="请输入内容" />
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <div class="gezi">
                  <el-form-item label="项目地址：">
                    {{ project.address }}
                  </el-form-item>
                </div>
                <div class="gezi">
                  <el-form-item label="气候区域：">
                    <span v-if="project.weather == 1">夏热冬冷</span>
                    <span v-if="project.weather == 2">寒冷</span>
                  </el-form-item>
                </div>
                <div class="gezi">
                  <el-form-item label="设计单位：">
                    <el-input v-model="project.design_company" placeholder="请输入内容"></el-input>
                  </el-form-item>
                </div>
                <div class="gezi">
                  <el-form-item label="监理单位：">
                    <el-input v-model="project.manger_company" placeholder="请输入内容"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-form ref="formtable" :model="formtable" label-width="180px">
        <div class="engerytable">
          <table border="1" cellspacing="0" width="100%">
            <tbody>
              <tr>
                <th colspan="8" scope="col" style="height: 40px">
                  &nbsp;测评内容
                </th>
                <th scope="col">&nbsp;测评方法</th>
                <th scope="col">&nbsp;测评结果</th>
              </tr>
              <tr>
                <th rowspan="3" scope="row">基础类&nbsp;</th>
                <td colspan="3" style="background-color:#a3c1dd2d">&nbsp;供暖耗能（kW·h/m²）</td>
                <td colspan="2">
                  <textarea v-model="formtable.heatEnergy" name="heatEnergy" placeholder="请输入内容" type="text" />
                </td>
                <td colspan="2" rowspan="3">&nbsp; 相对节能率（%）</td>
                <td rowspan="3"> 
                  <el-select v-model="formtable.evaluationWay_heatEnergy" clearable placeholder="软件评估">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td rowspan="3">
                  <!-- <el-select
                  v-model="formtable.evaInstitu_heatEnergy"
                  clearable
                  placeholder="符合要求"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                  <textarea v-model="formtable.evaInstitu_heatEnergy" placeholder="请输入内容" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;空调能耗（kW·h/m²）</td>
                <td colspan="2">
                  <textarea v-model="formtable.airEnergy" placeholder="请输入内容" type="text" />
                </td>
                <!-- <td>
              <el-select
                  v-model="formtable.evaluationWay_airEnergy"
                  clearable
                  placeholder="文件审查"
              >
                <el-option label="软件评估" value="1"></el-option>
                <el-option label="文件审查" value="2"></el-option>
                <el-option label="现场检查" value="3"></el-option>
                <el-option label="性能检查" value="4"></el-option>
                <el-option label="文件审查；现场检查" value="5"></el-option>
                <el-option label="文件审查；性能检测" value="6"></el-option>
                <el-option
                    label="文件审查；现场检查；性能检测"
                    value="7"
                ></el-option>
              </el-select>
            </td> -->
                <!-- <td>
              <input
                  v-model="formtable.evaInstitu_airEnergy"
                  placeholder="请输入内容"
                  type="text"
              />
            </td> -->
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">
                  单位建筑面积全年能耗量（kWh/m² )
                </td>
                <td colspan="2">
                  <textarea v-model="formtable.buildEnergy" placeholder="请输入内容" type="text" />
                </td>
                <!-- <td>
              <el-select
                  v-model="formtable.evaluationWay_buildEnergy"
                  clearable
                  placeholder="文件审查；现场检查"
              >
                <el-option label="软件评估" value="1"></el-option>
                <el-option label="文件审查" value="2"></el-option>
                <el-option label="现场检查" value="3"></el-option>
                <el-option label="性能检查" value="4"></el-option>
                <el-option label="文件审查；现场检查" value="5"></el-option>
                <el-option label="文件审查；性能检测" value="6"></el-option>
                <el-option
                    label="文件审查；现场检查；性能检测"
                    value="7"
                ></el-option>
              </el-select>
            </td> -->
                <!-- <td>
              <input
                  v-model="formtable.evaInstitu_buildEnergy"
                  placeholder="请输入内容"
                  type="text"
              />
            </td> -->
              </tr>
              <tr>
                <th :rowspan="
                  29 +
                  jizu1.length +
                  jizu2.length +
                  jizu3.length +
                  jizu4.length
                " scope="row">
                  &nbsp;规定项
                </th>
                <td rowspan="5" style="background-color:#a3c1dd2d">&nbsp;围护结构</td>
                <td colspan="2" style="background-color:#a3c1dd2d">&nbsp;外窗、透明幕墙气密性</td>
                <td colspan="4">
                  <textarea v-model="formtable.wallGas" placeholder="请输入内容" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_wallGas" clearable placeholder="文件审查；现场检查">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_wallGas" clearable placeholder="符合要求">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_wallGas"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" style="background-color:#a3c1dd2d">热桥部位&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.hotPart" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_hotPart" clearable placeholder="文件审查；现场检查">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_hotPart" clearable placeholder="符合要求">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_hotPart"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">门窗保温&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.doorHeat" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_doorHeat" clearable placeholder="文件审查；现场检查">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_hotPart" clearable placeholder="符合要求">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_doorHeat"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">透明幕墙可开启面积</td>
                <td colspan="4">
                  <textarea v-model="formtable.wallOpenArea" placeholder="请输入内容" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_wallOpenArea" clearable placeholder="文件审查；现场检查">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_wallOpenArea" clearable placeholder="符合要求">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_wallOpenArea"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">外遮阳&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.outShade" placeholder="请输入内容" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_outShade" clearable placeholder="文件审查；现场检查">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_outShade" clearable placeholder="符合要求">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_outShade"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td :rowspan="
                  19 +
                  jizu1.length +
                  jizu2.length +
                  jizu3.length +
                  jizu4.length
                " scope="row" style="background-color:#a3c1dd2d">
                  冷 热 源 及 空 调 系 统
                </td>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">空调冷源</td>
                <td colspan="4">
                  <textarea v-model="formtable.coldSource" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_coldSource" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_coldSource" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_coldSource"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">供暖热源&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.hotSource" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_hotSource" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_hotSource" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_hotSource"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" rowspan="2" scope="row" style="background-color:#a3c1dd2d">&nbsp; 锅炉</td>
                <td colspan="2" style="background-color:#a3c1dd2d">类型&nbsp;</td>
                <td colspan="2" style="background-color:#a3c1dd2d">&nbsp; 额定热效率（%）</td>
                <td style="background-color:#a3c1dd2d"></td>
                <td style="background-color:#a3c1dd2d"></td>
              </tr>
              <tr>
                <th colspan="2" scope="row">
                  <textarea v-model="formtable.boilerType" placeholder="——" type="text" />
                </th>
                <td colspan="2">
                  <textarea v-model="formtable.rateEffic" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_rateEffic" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_rateEffic" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_rateEffic"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td :rowspan="jizu1.length + 1" colspan="2" scope="row" style="background-color:#a3c1dd2d">
                  冷水（热泵）机组
                  <div class="zengjia">
                    <el-button type="primary" @click="addjizu(1)" >
                      增加 冷水（热泵）机组
                    </el-button>
                  </div>
                </td>
                <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
                <td style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量（kW）</td>
                <td style="background-color:#a3c1dd2d">&nbsp;台数</td>
                <td style="background-color:#a3c1dd2d">&nbsp;性能系数（COP）</td>
                <td style="background-color:#a3c1dd2d"></td>
                <td style="background-color:#a3c1dd2d"></td>
              </tr>
              <tr v-for="(item, index) in jizu1" :key="item">
                <th scope="row">
                  <textarea v-model="item.unitType" placeholder="——" type="text" />
                </th>
                <td>
                  <textarea v-model="item.unitMeasure" placeholder="——" type="text" />
                </td>
                <td>
                  <textarea v-model="item.unitNum" placeholder="——" type="text" />
                </td>
                <td>
                  <textarea v-model="item.unitErr" placeholder="——" type="text" />
                </td>
                <td v-if="index == 0" :rowspan="jizu1.length">
                  <el-select v-model="item.evaluationWay" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td v-if="index == 0" :rowspan="jizu1.length">
                  <el-select v-model="item.evaInstitu" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input v-model="item.evaInstitu" placeholder="直接输入分值（示例：5）" type="text" /> -->
                </td>
              </tr>
              <tr>
                <td :rowspan="jizu2.length + 1" colspan="2" scope="row" style="background-color:#a3c1dd2d">
                  单元式空气调节机、风管送风式和屋顶调节机组
                  <div class="zengjia">
                    <el-button type="primary" @click="addjizu(2)">
                      单元式空气调节机、风管送风式和屋顶调节机组
                    </el-button>
                  </div>
                </td>
                <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
                <td style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量（kW）</td>
                <td style="background-color:#a3c1dd2d">&nbsp; 台数</td>
                <td style="background-color:#a3c1dd2d">&nbsp;能效比（EER）</td>
                <td style="background-color:#a3c1dd2d"></td>
                <td style="background-color:#a3c1dd2d"></td>
              </tr>
              <tr v-for="(item, index) in jizu2" :key="item">
                <th scope="row">
                  <textarea v-model="item.unitType" placeholder="——" type="text" />
                </th>
                <td>
                  <textarea v-model="item.unitMeasure" placeholder="——" type="text" />
                </td>
                <td>
                  <textarea v-model="item.unitNum" placeholder="——" type="text" />
                </td>
                <td>
                  <textarea v-model="item.unitErr" placeholder="——" type="text" />
                </td>
                <td v-if="index == 0" :rowspan="jizu2.length">
                  <el-select v-model="item.evaluationWay" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                  </el-select>
                </td>
                <td v-if="index == 0" :rowspan="jizu2.length">
                  <el-select v-model="item.evaInstitu" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input v-model="item.evaInstitu" placeholder="直接输入分值（示例：5）" type="text" /> -->
                </td>
              </tr>
              <!-- <tr>
    <th scope="col" colspan="8" style="height:40px">测评内容&nbsp;</th>
    <th scope="col">测评方法&nbsp;</th>
    <th scope="col">&nbsp;测评结果</th>

  </tr> -->
              <tr>
                <!-- <th scope="row" :rowspan="18+jizu3.length+jizu4.length">&nbsp;规定项</th> -->
                <!-- <td  :rowspan="13+jizu3.length+jizu4.length">冷热源及空调系统</td> -->
                <td :rowspan="jizu3.length + 1" colspan="2" style="background-color:#a3c1dd2d">
                  多联式空调（热泵）机组
                  <div class="zengjia">
                    <el-button type="primary" @click="addjizu(3)">
                      增加 多联式空调（热泵）机组
                    </el-button>
                  </div>
                </td>
                <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
                <td style="background-color:#a3c1dd2d">&nbsp;单机额定制冷量(kW)</td>
                <td style="background-color:#a3c1dd2d">&nbsp;台数</td>
                <td style="background-color:#a3c1dd2d">&nbsp;综合性能系数(IPLV)</td>
                <td style="background-color:#a3c1dd2d"></td>
                <td style="background-color:#a3c1dd2d"></td>
              </tr>
              <tr v-for="(item, index) in jizu3" :key="item">
                <th scope="row">
                  <textarea v-model="item.unitType" placeholder="——" type="text" />
                </th>
                <td>
                  <textarea v-model="item.unitMeasure" placeholder="——" type="text" />
                </td>
                <td>
                  <textarea v-model="item.unitNum" placeholder="——" type="text" />
                </td>
                <td>

                  <textarea v-model="item.unitErr" placeholder="——" type="text" />
                </td>
                <td v-if="index == 0" :rowspan="jizu3.length">
                  <el-select v-model="item.evaluationWay" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td v-if="index == 0" :rowspan="jizu3.length">
                  <el-select v-model="item.evaInstitu" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input v-model="item.evaInstitu" placeholder="直接输入分值（示例：5）" type="text" /> -->
                </td>
              </tr>
              <tr>
                <td :rowspan="jizu4.length + 1" colspan="2" scope="row" style="background-color:#a3c1dd2d">
                  溴化锂吸收式机组
                  <div class="zengjia">
                    <el-button type="primary" @click="addjizu(4)">
                      增加溴化锂吸收式机组
                    </el-button>
                  </div>
                </td>
                <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
                <td style="background-color:#a3c1dd2d"> &nbsp; 单机额定制冷量(kW)</td>
                <td style="background-color:#a3c1dd2d">&nbsp; 台数</td>
                <td style="background-color:#a3c1dd2d">&nbsp; 综合性能系数(IPLV)</td>
                <td style="background-color:#a3c1dd2d">&nbsp;</td>
                <td style="background-color:#a3c1dd2d">&nbsp;</td>
              </tr>
              <tr v-for="(item, index) in jizu4" :key="item">
                <th scope="row">
                  <textarea v-model="item.unitType" placeholder="——" type="text" />
                </th>
                <td>
                  <textarea v-model="item.unitMeasure" placeholder="——" type="text" />
                </td>
                <td>
                  <textarea v-model="item.unitNum" placeholder="——" type="text" />
                </td>
                <td>
                  <textarea v-model="item.unitErr" placeholder="——" type="text" />
                </td>
                <td v-if="index == 0" :rowspan="jizu4.length">
                  <el-select v-model="item.evaluationWay" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td v-if="index == 0" :rowspan="jizu4.length">
                  <el-select v-model="item.evaInstitu" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input v-model="item.evaInstitu" placeholder="直接输入分值（示例：5）" type="text" /> -->
                </td>
              </tr>

              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">室内温度</td>
                <td colspan="4">
                  <textarea v-model="formtable.indoorTem" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_indoorTem" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_indoorTem" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_indoorTem"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">新风量&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.windNum" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_windNum" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_windNum" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_windNum"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">
                  集中供暖系统热水循环水泵的耗电输热比
                </td>
                <td colspan="4">
                  <textarea v-model="formtable.focusRatio" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_focusRatio" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_focusRatio" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_focusRatio"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">风机单位风量耗功率</td>
                <td colspan="4">
                  <textarea v-model="formtable.windPower" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_windPower" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_windPower" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_windPower"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">空调水系统耗电输热比</td>
                <td colspan="4">
                  <textarea v-model="formtable.airRatio" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_airRatio" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_airRatio" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_airRatio"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">室温调节设施</td>
                <td colspan="4">
                  <textarea v-model="formtable.indoorFacil" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_indoorFacil" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_indoorFacil" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_indoorFacil"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td rowspan="3" scope="row" style="background-color:#a3c1dd2d">计量装置</td>
                <td style="background-color:#a3c1dd2d">&nbsp;集中供暖系统</td>
                <td colspan="4">
                  <textarea v-model="formtable.facusSystem" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_facusSystem" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_facusSystem" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_facusSystem"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td scope="row" style="background-color:#a3c1dd2d">区域冷/热源&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.regionSource" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_regionSource" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_regionSource" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_regionSource"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td scope="row" style="background-color:#a3c1dd2d">制冷站空调系统&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.coldSystem" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_coldSystem" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_coldSystem" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_coldSystem"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">水力平衡</td>
                <td colspan="4">
                  <textarea v-model="formtable.waterBalence" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_waterBalence" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_waterBalence" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_waterBalence"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">监测和控制系统</td>
                <td colspan="4">
                  <textarea v-model="formtable.detectSystem" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_detectSystem" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_detectSystem" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <textarea
                  v-model="formtable.evaInstitu_detectSystem"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td rowspan="3" scope="row" style="background-color:#a3c1dd2d">照明系统</td>
                <td colspan="2" style="background-color:#a3c1dd2d">&nbsp;照明功率密度</td>
                <td colspan="4">
                  <textarea v-model="formtable.lightDensity" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_lightDensity" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_lightDensity" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_lightDensity"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">照度的时序自动控制装置</td>
                <td colspan="4">
                  <textarea v-model="formtable.lightAuto" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_lightAuto" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_lightAuto" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <textarea
                  v-model="formtable.evaInstitu_lightAuto"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">&nbsp;降低照度控制措施</td>
                <td colspan="4">
                  <textarea v-model="formtable.lowerlight" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_lowerlight" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_lowerlight" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_lowerlight"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td rowspan="2" scope="row" style="background-color:#a3c1dd2d">生活热水系统</td>
                <td colspan="2" style="background-color:#a3c1dd2d">&nbsp;热泵性能</td>
                <td colspan="4">
                  <textarea v-model="formtable.heatPump" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_heatPump" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_heatPump" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_heatPump"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <tr>
                <td colspan="2" scope="row" style="background-color:#a3c1dd2d">热水监控&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.hotMonitor" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_hotMonitor" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <el-select v-model="formtable.evaInstitu_hotMonitor" clearable placeholder="——">
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option label="不符合要求" value="不符合要求"></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select>
                  <!-- <input
                  v-model="formtable.evaInstitu_hotMonitor"
                  placeholder="直接输入分值（示例：5）"
                  type="text"
                /> -->
                </td>
              </tr>
              <!-- <tr>
        <th scope="col" colspan="8" style="height:40px">&nbsp;测评内容</th>
        <th scope="col">&nbsp;测评方法</th>
        <th scope="col">&nbsp;测评结果</th>
      </tr> -->
              <tr>
                <th rowspan="19" scope="row" style="background-color:#a3c1dd2d">选择项&nbsp;</th>
                <td rowspan="5" style="background-color:#a3c1dd2d">&nbsp;可再生能源</td>
                <td style="background-color:#a3c1dd2d">&nbsp;太阳能热水系统</td>
                <td style="background-color:#a3c1dd2d">集热效率（%）&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.gatherHot" placeholder="请直接输入数字" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_gatherHot" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_gatherHot"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_gatherHot" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td scope="row" style="background-color:#a3c1dd2d">可再生能源发电系统&nbsp;</td>
                <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
                <td colspan="4">
                  <textarea v-model="formtable.renewRatio" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_renewRatio" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_renewRatio"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_renewRatio" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td scope="row" style="background-color:#a3c1dd2d">太阳能光伏系统&nbsp;</td>
                <td style="background-color:#a3c1dd2d">&nbsp;光电转换效率</td>
                <td colspan="4">
                  <textarea v-model="formtable.lightEffic" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_lightEffic" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_lightEffic"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_lightEffic" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td scope="row" style="background-color:#a3c1dd2d">&nbsp;地源热泵系统</td>
                <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
                <td colspan="4">
                  <textarea v-model="formtable.earthRatio" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_earthRatio" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_earthRatio"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_earthRatio" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td scope="row" style="background-color:#a3c1dd2d">空气源热泵&nbsp;</td>
                <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
                <td colspan="4">
                  <textarea v-model="formtable.airPump" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_airPump" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_airPump"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_airPump" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;自然通风</td>
                <td colspan="4">
                  <textarea v-model="formtable.natureWind" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_natureWind" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_natureWind"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_natureWind" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;自然采光</td>
                <td colspan="4">
                  <textarea v-model="formtable.natureLight" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_natureLight" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_natureLight"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_natureLight" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;遮阳措施</td>
                <td colspan="4">
                  <textarea v-model="formtable.lightMeasure" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_lightMeasure" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_lightMeasure"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_lightMeasure" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;能量回放</td>
                <td colspan="4">
                  <textarea v-model="formtable.powerBack" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_powerBack" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_powerBack"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_powerBack" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;蓄冷蓄热技术</td>
                <td colspan="4">
                  <textarea v-model="formtable.saveSkill" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_saveSkill" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_saveSkill"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_saveSkill" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">冷凝热利用&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.coldHotUse" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_coldHotUse" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_coldHotUse"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_coldHotUse" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;全新风/可变新风比</td>
                <td colspan="4">
                  <textarea v-model="formtable.newWind" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_newWind" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_newWind"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_newWind" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;变流量/变风量</td>
                <td colspan="4">
                  <textarea v-model="formtable.changeWind" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_changeWind" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_changeWind"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_changeWind" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">供回水温差&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.changeTem" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_changeTem" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_changeTem"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_changeTem" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;楼宇自控</td>
                <td colspan="4">
                  <textarea v-model="formtable.buildControl" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_buildControl" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_buildControl"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_buildControl" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;计量统计+节能控制措施</td>
                <td colspan="4">
                  <textarea v-model="formtable.energyControl" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_energyControl" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_energyControl"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_energyControl" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">冷热源设备能效等级&nbsp;</td>
                <td colspan="4">
                  <textarea v-model="formtable.energyGrade" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_energyGrade" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_energyGrade"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_energyGrade" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;风扇调风</td>
                <td colspan="4">
                  <textarea v-model="formtable.airCond" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_airCond" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_airCond"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_airCond" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;其他新型节能措施</td>
                <td colspan="4">
                  <textarea v-model="formtable.otherMeasure" placeholder="——" type="text" />
                </td>
                <td>
                  <el-select v-model="formtable.evaluationWay_otherMeasure" clearable placeholder="——">
                    <el-option label="软件评估" value="1"></el-option>
                    <el-option label="文件审查" value="2"></el-option>
                    <el-option label="现场检查" value="3"></el-option>
                    <el-option label="性能检查" value="4"></el-option>
                    <el-option label="文件审查；现场检查" value="5"></el-option>
                    <el-option label="文件审查；性能检测" value="6"></el-option>
                    <el-option label="文件审查；现场检查；性能检测" value="7"></el-option>
                    <el-option label="——" value="8"></el-option>
                  </el-select>
                </td>
                <td>
                  <!-- <el-select
                    v-model="formtable.evaInstitu_otherMeasure"
                    clearable
                    placeholder="——"
                  >
                    <el-option label="符合要求" value="符合要求"></el-option>
                    <el-option
                      label="不符合要求"
                      value="不符合要求"
                    ></el-option>
                    <el-option label="——" value="——"></el-option>
                  </el-select> -->
                  <input v-model="formtable.evaInstitu_otherMeasure" placeholder="直接输入分值（示例：5）" type="text" />
                </td>
              </tr>
              <tr>
                <td colspan="10" scope="row">
                  <div class="minyong">
                    <p>1、民用建筑能效测评结论：</p>
                    <p>
                      （1）经软件模拟该项目基础相对节能率为<input v-model="formtable.relateEnergy" type="text"
                        placeholder="请输入内容" />%,(设计标准为
                      <span class="minyong_select">
                        <el-select v-model="formtable.designStand">
                          <el-option label="《建筑节能与可再生能源利用通用规范》GB 55015-2021" value="1"></el-option>
                          <el-option label="《公共建筑节能设计标准》DGJ32J96-2010" value="2"></el-option>
                          <el-option label="《公共建筑节能设计标准》GB50189-2015" value="3"></el-option>
                        </el-select>
                      </span>
                      <span> ); </span>
                    </p>
                    <p>
                      （2）经测评，该项目规定项<input v-model="formtable.strip" type="text"
                        placeholder="请输入内容" />条参评，均满足《民用建筑能效测评标识标准》DB32/T
                      3964-2020规定要求；
                    </p>
                    <p>
                      （3）经测评，该项目选择项<input v-model="formtable.selectStrip" type="text" placeholder="请输入内容" />条参评，加分<input
                        v-model="formtable.grades" type="text" placeholder="请输入内容" />分；
                    </p>
                    <p>
                      （4）经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                      3964-2020标准要求，建筑节能率为<input v-model="formtable.buildEnergyRatio" type="text"
                        placeholder="请输入内容" />%，测评合格。
                    </p>
                    <p>2、民用建筑能效标识建议：</p>
                    <p>
                      依据民用建筑能效测评结论，建议该建筑能效标识为
                      <el-select v-model="form.stars">
                        <el-option :label="'一'" :value="1"></el-option>
                        <el-option :label="'二'" :value="2"></el-option>
                        <el-option :label="'三'" :value="3"></el-option>
                      </el-select>
                      星。
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <th colspan="4" scope="row">测评人员&nbsp;</th>
                <th colspan="4">&nbsp;审核人员</th>
                <th colspan="4">&nbsp;批准人员</th>
              </tr>
              <tr class="table_select">
                <th colspan="4" scope="row">
                  <el-select v-model="formtable.evaluatUser" v-loadmore="getStaffList" :filter-method="fliterStaff"
                    filterable placeholder="盖章/签字" @focus="selectFocus(1)">
                    <el-option v-for="item in staffList" :key="item.id" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                  <!--              <input v-model="formtable.evaluatUser" placeholder="盖章/签字" type="text">-->
                </th>
                <td colspan="4">
                  <el-select v-model="formtable.auditUser" v-loadmore="getStaffList" :filter-method="fliterStaff"
                    filterable placeholder="盖章/签字" @focus="selectFocus(2)">
                    <el-option v-for="item in staffList2" :key="item.id" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                  <!--              <input v-model="formtable.auditUser" placeholder="盖章/签字" type="text">-->
                </td>
                <td colspan="4">
                  <el-select v-model="formtable.approveUser" v-loadmore="getStaffList" :filter-method="fliterStaff"
                    filterable placeholder="盖章/签字" @focus="selectFocus(3)">
                    <el-option v-for="item in staffList3" :key="item.id" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                  <!--              <input v-model="formtable.approveUser" placeholder="盖章/签字" type="text">-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="uploadtable">
          <el-row :gutter="20">
            <el-col :span="14">
              <div class="grid-content bg-purple">
                <div class="gezi">
                  <el-form-item label="上传能效测评报告">
                    <el-upload :file-list="fileList" :on-success="fileUploadSuccess" :on-preview="downloadFile"
                      accept=".docx,.pdf" action="https://api.gbservice.cn/api/public/bupload/" class="upload-demo">
                      <el-button size="small" type="primary">点击上传</el-button>
                      <span slot="tip" class="el-upload__tip">
                        选择上传文件（仅限pdf/docx、点击已上传文件名可下载）
                      </span>
                    </el-upload>
                  </el-form-item>
                </div>
                <div class="gezi">
                  <el-form-item label="上传附件">
                    <el-upload :file-list="photo" :on-success="photo_success" :on-preview="downloadFile"
                      action="https://api.gbservice.cn/api/public/bupload/" class="upload-demo">
                      <el-button size="small" type="primary">点击上传</el-button>
                      <span slot="tip" class="el-upload__tip">
                        选择上传文件
                      </span>
                    </el-upload>
                  </el-form-item>
                  <div class="fileright">
                    （注：请将能耗计算模型、建筑、暖通和电气图纸打包上传）
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <div class="gezi name_input">
                  <el-form-item label="能效测评报告编号">
                    <el-input v-model="project.number"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="comit">
        <div class="baocun">
          <el-button type="primary" @click="pre_page">上一页</el-button>
        </div>
        <div class="baocun">
          <el-button type="primary" @click="saveProject(1)">保存</el-button>
        </div>
        <div class="baocun">
          <el-button type="primary" @click="next_page">生成《申报指标情况表》</el-button>
        </div>

        <!--      <div class="baocun">-->
        <!--        <el-button type="primary" @click="saveProject(2)">提交</el-button>-->
        <!--      </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import { editTableProject, projectTableInfo } from "../../../../api/project";
import JiangSu from "../../../../assets/jiangsu.json";
import { getStaff } from "../../../../api/user";
import axios from "axios";

export default {
  data() {
    return {
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      formtable: {
        evaluationWay_heatEnergy: "1",
        evaluationWay_wallGas: "5",
        evaluationWay_hotPart: "5",
        evaluationWay_doorHeat: "5",
        evaluationWay_wallOpenArea: "5",
        evaluationWay_outShade: "5",
        evaInstitu_wallGas: "符合要求",
        evaInstitu_hotPart: "符合要求",
        // evaInstitu_hotPart: "符合要求",
        evaInstitu_doorHeat: "符合要求",
        evaInstitu_wallOpenArea: "符合要求",
        evaInstitu_outShade: "符合要求",
      },
      form: {
        stars: 1,
      },
      cityOptions: JiangSu.city,
      countyOptions: [],
      weatherOptions: [
        {
          name: "办公建筑",
          id: 1,
        },
        {
          name: "商场建筑",
          id: 2,
        },
        {
          name: "宾馆饭店建筑",
          id: 3,
        },
        {
          name: "文化教育建筑",
          id: 4,
        },
        {
          name: "医疗卫生建筑",
          id: 5,
        },
        {
          name: "其他建筑",
          id: 6,
        },
      ],

      photo: [],

      //冷水热泵机组
      jizu1: [],

      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],

      jizu3: [],
      jizu4: [],

      project: {},

      staffList: [],
      staffList2: [],
      staffList3: [],
      staffPage: 2,
      staffPage2: 2,
      staffPage3: 2,
      activeStaff: 1,

      project_id: "",

      tmp_files: [],
    };
  },

  mounted() {
    this.getAllStaffList();
    let project_id = this.$route.query.project_id;
    this.project_id = project_id;
    this.getInfo();
    this.getCache();
    this.onBlur();
  },

  methods: {

    downloadFile(file) {
      console.log(file);
      let url = file.url;
      let fileName = file.name;
      axios.get(url, { responseType: 'blob', params: {} }).then((res) => {
        if (!res.data) {
          this.$message.warning(fileName + "下载失败！");
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([res.data]), fileName);
        } else {
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      }).catch(() => {
        this.$message.warning(file + "下载失败！");
      });

    },

    // 返回
    goback() {
      this.$router.push("/user_mark/itemdeclare");
    },
    // 设置失去焦点事件
    onBlur() {
      let that = this;
      let formDOM = document.getElementById("form");
      let input = formDOM.getElementsByTagName("input");
      if (input && input.length > 0) {
        for (let i = 0; i < input.length; i++) {
          input[i].onblur = function () {
            that.saveCache();
          };
        }
      }
    },
    //自动保存填写的信息到缓存中
    saveCache() {
      if (this.toCache) {
        console.log("保存到缓存");
        if (this.form) {
          localStorage.setItem("itde_form", JSON.stringify(this.form));
        }
        if (this.fileList) {
          localStorage.setItem("itde_fileList", JSON.stringify(this.fileList));
        }
        if (this.photo) {
          localStorage.setItem("itde_photo", JSON.stringify(this.photo));
        }
      }
    },
    //清除缓存
    clearCache() {
      localStorage.removeItem("itde_form");
      localStorage.removeItem("itde_fileList");
      localStorage.removeItem("itde_photo");
    },
    //获得缓存中的信息
    getCache() {
      let form = localStorage.getItem("itde_form");
      if (form && form != "") {
        this.form = JSON.parse(form);
      }
      let fileList = localStorage.getItem("itde_fileList");
      if (fileList && fileList != "") {
        this.fileList = JSON.parse(fileList);
      }
      let photo = localStorage.getItem("itde_photo");
      if (photo && photo != "") {
        this.photo = JSON.parse(photo);
      }
    },
    /**
     * 筛选企业
     */
    fliterStaff(searchVlaue) {
      this.staffList = [];
      this.staffPage = 1;
      this.getStaffList(searchVlaue);
    },

    //select聚焦
    selectFocus(active) {
      this.activeStaff = active;
      this.getStaffList();
    },

    getAllStaffList() {
      let params = {
        token: this.$store.state.token,
        limit: 1000,
        page: 1,
      };
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          this.staffList = res.data;
          this.staffList2 = res.data;
          this.staffList3 = res.data;
        }
      });
    },

    //获得员工信息
    getStaffList(val = "") {
      let that = this;
      let params = {
        token: this.$store.state.token,
        limit: 1000,
      };
      if (this.activeStaff == 1) {
        params.page = this.staffPage;
      } else if (this.activeStaff == 2) {
        params.page = this.staffPage2;
      } else {
        params.page = this.staffPage3;
      }
      if (val != "") {
        params.name = val;
      }
      getStaff(params).then((res) => {
        console.log(res.data);
        if (res.data && res.data.length > 0) {
          if (this.activeStaff == 1) {
            that.staffList = that.staffList.concat(res.data);
            that.staffPage += 1;
          } else if (this.activeStaff == 2) {
            that.staffList2 = that.staffList2.concat(res.data);
            that.staffPage2 += 1;
          } else {
            that.staffList3 = that.staffList3.concat(res.data);
            that.staffPage3 += 1;
          }
        } else {
          if (params.page == 1) {
            that.$message.warning("请先进入管理账户管理添加人员")
          }
        }
      });
    },

    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;
        //获取文件
        let file1 = [];
        let file2 = [];
        if (this.form.files && this.form.files.length > 0) {
          file1 = this.form.files
            .map((item) => {
              if (item.type == 3) {
                item.name = item.file;
                return item;
              }
            })
            .filter((d) => d);
        }
        this.fileList = file1;
        if (this.form.files && this.form.files.length > 0) {
          file2 = this.form.files
            .map((item) => {
              if (item.type == 4) {
                item.name = item.file;
                return item;
              }
            })
            .filter((d) => d);
        }
        this.photo = file2;

        let pre_files = this.form.files;
        let tmp_files = [];
        for (let i = 0; i < pre_files.length; i++) {
          if (pre_files[i].type != 3 && pre_files[i].type != 4) {
            tmp_files.push(pre_files[i]);
          }
        }
        this.tmp_files = tmp_files;
        console.log(tmp_files);
        if (that.form.detail) {
          //value为对象
          Object.keys(that.form.detail).map((item) => {
            if (!that.form.detail[item] || that.form.detail[item] == "") {
              delete that.form.detail[item];
            }
            return true;
          });
        }

        that.formtable = Object.assign(that.formtable, that.form.detail);
        if (!that.formtable) {
          that.formtable = {};
        }
        // if (that.formtable.evaluatUser){
        //   that.formtable.evaluatUser = parseInt(that.formtable.evaluatUser)
        // }
        // if (that.formtable.auditUser){
        //   that.formtable.auditUser = parseInt(that.formtable.auditUser)
        // }
        // if (that.formtable.approveUser){
        //   that.formtable.approveUser = parseInt(that.formtable.approveUser)
        // }
        let project = {
          name: that.form.name,
          building_area: that.form.building_area,
          build_company: that.form.build_company,
          floor: that.form.floor,
          address: that.form.address,
          weather: that.form.weather,
          design_company: that.form.design_company,
          manger_company: that.form.manger_company,
          work_build: that.form.work_build,
          number: that.form.number,
        };
        that.project = project;
        let jizu_total = that.form.jizhu;
        let jizu1 = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu1.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        if (jizu1.length == 0) {
          jizu1.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum: "——",
            unitErr: "——"
          })
        }
        if (jizu2.length == 0) {
          jizu2.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum: "——",
            unitErr: "——"
          })
        }
        if (jizu3.length == 0) {
          jizu3.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum: "——",
            unitErr: "——"
          })
        }
        if (jizu4.length == 0) {
          jizu4.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum: "——",
            unitErr: "——"
          })
        }
        if (jizu5.length == 0) {
          jizu5.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum: "——",
            unitErr: "——"
          })
        }
        if (jizu6.length == 0) {
          jizu6.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum: "——",
            unitErr: "——"
          })
        }
        that.jizu1 = jizu1;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
      });
    },

    //上一页，保存
    pre_page() {
      let that = this;
      let params = {};
      params.jizu1 = this.jizu1;
      params.jizu2 = this.jizu2;
      params.jizu3 = this.jizu3;
      params.jizu4 = this.jizu4;
      params.jizu5 = this.jizu5;
      params.jizu6 = this.jizu6;
      params.detail = this.formtable;
      params.project = this.project;
      params.id = this.project_id;
      params.project_id = this.project_id;

      params.save = 1;
      params.files = [];
      params.token = this.$store.state.token;
      if (this.fileList && this.fileList.length > 0) {
        params.files = this.fileList.map((item) => {
          item.type = 3;
          item.eid = undefined;
          item.file = undefined;
          item.id = undefined;
          item.status = undefined;
          item.uid = undefined;
          return item;
        });
      }
      if (this.photo && this.photo.length > 0) {
        for (let i = 0; i < this.photo.length; i++) {
          this.photo[i].type = 4;
          this.photo[i].eid = undefined;
          this.photo[i].file = undefined;
          this.photo[i].id = undefined;
          this.photo[i].status = undefined;
          this.photo[i].uid = undefined;
        }
        params.files = params.files.concat(this.photo);
      }

      if (this.tmp_files && this.tmp_files.length > 0) {
        params.files = params.files.concat(this.tmp_files);
      }
      if (params.files && params.files.length > 0) {
        for (let i = 0; i < params.files.length; i++) {
          if (!params.files[i].name) {
            params.files[i].name = params.files[i].url;
          }
          params.files[i].eid = undefined;
          params.files[i].file = undefined;
          params.files[i].id = undefined;
          params.files[i].status = undefined;
          params.files[i].uid = undefined;
        }
      }
      editTableProject(params)
        .then((res) => {
          console.log(res);
          that.$router.push({
            path: "./itdeAdd?project_id=" + that.project_id,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //下一页
    next_page() {
      if (!this.formtable.evaluatUser || this.formtable.evaluatUser.replace(/\s/g, '') == '') {
        this.$message.warning("请选择测评人员");
        return false;
      }
      if (!this.formtable.auditUser || this.formtable.auditUser.replace(/\s/g, '') == '') {
        this.$message.warning("请选择审核人员");
        return false;
      }
      if (!this.formtable.approveUser || this.formtable.approveUser.replace(/\s/g, '') == '') {
        this.$message.warning("请选择批准人员");
        return false;
      }
      let that = this;
      let params = {};
      params.jizu1 = this.jizu1;
      params.jizu2 = this.jizu2;
      params.jizu3 = this.jizu3;
      params.jizu4 = this.jizu4;
      params.jizu5 = this.jizu5;
      params.jizu6 = this.jizu6;
      params.detail = this.formtable;
      params.project = this.project;
      params.id = this.project_id;
      params.project_id = this.project_id;

      params.save = 1;
      params.files = [];
      params.token = this.$store.state.token;
      if (this.fileList && this.fileList.length > 0) {
        params.files = this.fileList.map((item) => {
          item.type = 3;
          item.eid = undefined;
          item.file = undefined;
          item.id = undefined;
          item.status = undefined;
          item.uid = undefined;
          return item;
        });
      }
      if (this.photo && this.photo.length > 0) {
        for (let i = 0; i < this.photo.length; i++) {
          this.photo[i].type = 4;
          this.photo[i].eid = undefined;
          this.photo[i].file = undefined;
          this.photo[i].id = undefined;
          this.photo[i].status = undefined;
          this.photo[i].uid = undefined;
        }
        params.files = params.files.concat(this.photo);
      }

      if (this.tmp_files && this.tmp_files.length > 0) {
        params.files = params.files.concat(this.tmp_files);
      }
      if (params.files && params.files.length > 0) {
        for (let i = 0; i < params.files.length; i++) {
          if (!params.files[i].name) {
            params.files[i].name = params.files[i].url;
          }
          params.files[i].eid = undefined;
          params.files[i].file = undefined;
          params.files[i].id = undefined;
          params.files[i].status = undefined;
          params.files[i].uid = undefined;
        }
      }
      editTableProject(params)
        .then((res) => {
          console.log(res);
          that.$router.push({
            path: "./nextTable?project_id=" + that.project_id,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //增加 冷水（热泵）机组
    addjizu(which) {
      if (which == 1) {
        this.jizu1.push({});
      } else if (which == 2) {
        this.jizu2.push({});
      } else if (which == 3) {
        this.jizu3.push({});
      } else {
        this.jizu4.push({});
      }
    },

    //切换市
    cityChange(city) {
      for (let i = 0; i < this.cityOptions.length; i++) {
        if (this.cityOptions[i].name == city) {
          this.countyOptions = this.cityOptions[i].area;
          break;
        }
      }
    },

    //保存项目
    saveProject() {
      let that = this;
      let params = {};
      params.jizu1 = this.jizu1;
      params.jizu2 = this.jizu2;
      params.jizu3 = this.jizu3;
      params.jizu4 = this.jizu4;
      params.jizu5 = this.jizu5;
      params.jizu6 = this.jizu5;

      this.formtable.windSystem = "";
      this.formtable.heatFore = "";
      this.formtable.coldTemAdjust = "";
      this.formtable.evaInstitu_coldTemAdjust = "";
      this.formtable.evaluationWay_coldTemAdjust = "";
      this.formtable.evaluationWay_heatFore = "";
      this.formtable.evaInstitu_heatFore = "";
      this.formtable.evaluationWay_windSystem = "";
      this.formtable.evaluationWay_heatAuito = "";
      this.formtable.evaInstitu_heatAuito = "";
      this.formtable.evaInstitu_windSystem = "";

      params.detail = this.formtable;
      params.project = this.project;
      params.id = this.project_id;
      params.project_id = this.project_id;

      params.save = 1;
      params.files = [];

      params.token = this.$store.state.token;
      if (this.fileList && this.fileList.length > 0) {
        params.files = this.fileList.map((item) => {
          item.type = 3;
          item.eid = undefined;
          item.file = undefined;
          item.id = undefined;
          item.status = undefined;
          item.uid = undefined;
          return item;
        });
      }
      if (this.photo && this.photo.length > 0) {
        for (let i = 0; i < this.photo.length; i++) {
          this.photo[i].type = 4;
          this.photo[i].eid = undefined;
          this.photo[i].file = undefined;
          this.photo[i].id = undefined;
          this.photo[i].status = undefined;
          this.photo[i].uid = undefined;
        }
        params.files = params.files.concat(this.photo);
      }

      if (this.tmp_files && this.tmp_files.length > 0) {
        params.files = params.files.concat(this.tmp_files);
      }
      if (params.files && params.files.length > 0) {
        for (let i = 0; i < params.files.length; i++) {
          if (!params.files[i].name) {
            params.files[i].name = params.files[i].url;
          }
          params.files[i].eid = undefined;
          params.files[i].file = undefined;
          params.files[i].id = undefined;
          params.files[i].status = undefined;
          params.files[i].uid = undefined;
        }
      }
      if (params.jizu1) {
        for (let i = 0; i < params.jizu1.length; i++) {
          delete params.jizu1[i].project_id;
          delete params.jizu1[i].id;
          delete params.jizu1[i].type;
        }
      }
      if (params.jizu2) {
        for (let i = 0; i < params.jizu2.length; i++) {
          delete params.jizu2[i].project_id;
          delete params.jizu2[i].id;
          delete params.jizu2[i].type;
        }
      }
      if (params.jizu3) {
        for (let i = 0; i < params.jizu3.length; i++) {
          delete params.jizu3[i].project_id;
          delete params.jizu3[i].id;
          delete params.jizu3[i].type;
        }
      }
      if (params.jizu4) {
        for (let i = 0; i < params.jizu4.length; i++) {
          delete params.jizu4[i].project_id;
          delete params.jizu4[i].id;
          delete params.jizu4[i].type;
        }
      }
      if (params.jizu5) {
        for (let i = 0; i < params.jizu5.length; i++) {
          delete params.jizu5[i].project_id;
          delete params.jizu5[i].id;
          delete params.jizu5[i].type;
        }
      }
      if (params.jizu6) {
        for (let i = 0; i < params.jizu6.length; i++) {
          delete params.jizu6[i].project_id;
          delete params.jizu6[i].id;
          delete params.jizu6[i].type;
        }
      }

      editTableProject(params)
        .then((res) => {
          console.log(res);
          that.$message.success(res.message || "保存成功");
          localStorage.removeItem("form");
          // that.$router.push({
          //   path: "./itemdeclare",
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //图片上传成功
    photo_success(re, file, fileList) {
      this.photo = fileList.map((item) => {
        return item.response.data;
      });
    },

    fileUploadSuccess(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList = tmpList.map((item) => {
        return item.response.data;
      });
      console.log(this.fileList);
    },
  },
};
</script>
<style scoped>
.minyong .el-input__inner {
  border-bottom: 1px solid #333;
}

textarea {
  width: 98%;
  height: 40px;
  border: none;
  text-align: center;
  line-height: 40px;
  overflow: visible;
  font-size: 14px;
  word-break: break-all;
  background-color: #f4f4f4f4;
}

input {
  background-color: #f4f4f4f4;
}

textarea::-webkit-scrollbar {
  display: none;
}

/deep/textarea::placeholder {
  color: #66b1ff;
}

/deep/input::placeholder {
  color: #66b1ff;
}

/deep/ .minyong>.el-select {
  border-bottom: 1px solid #000;
}

/deep/ .engerytable .el-input--suffix .el-input__inner {
  width: 100%;
  border: 0;
  text-align: center;
}

/deep/.minyong .el-select {
  width: 335px !important;
}

/deep/.engerytable .el-select {
  width: 100%;
}

/deep/ .engerytable .el-input__suffix {
  right: 0px;
}

.table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/textarea::placeholder {
  color: #66b1ff;
}

/deep/ .minyong input {
  width: 250px;
  border-bottom: 1px solid #333 !important;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
  border-bottom: 1px solid #c0c4cc;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: 13px;
}

/* /deep/ .el-input {
  width: 250px;
} */
</style>
<style lang="scss" scoped>
th{
  background-color: #a3c1dd2d;
}
.dtcontent {
  height: 87vh;
  overflow-y: scroll;
}

.dtcontent::-webkit-scrollbar {
  display: none;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

.engerytable {
  margin: 20px;
}

.coianter {
  background-color: #ffffff;
  // height: 100%;
}

.idteail {
  margin: 4px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #f4f4f4f4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
    cursor: pointer;
  }
}

.gezi {
  // margin: 20px 0;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;

  .geziright {
    // margin-left: 20px;
  }

  .fileright {
    display: flex;
    flex-direction: row-reverse;
    color: #3086fb;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
  }
}

.tableform {
  margin: 34px 100px;
}

.comit {
  padding: 94px 0;
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .baocun {
    margin: 0 25px;
    display: flex;
    flex-direction: row;
  }
}

td {
  height: 40px;
}

.zengjia {
  margin: 10px 0;
}
</style>
